import Web3 from 'web3';
import web3Extension from '@energi/web3-ext';
import {provider} from './network-providers';

const web3 = new Web3(provider);

web3Extension.extend(web3);

const fetchBlockInformation = async (blockNumber) => {
    try {
        const block = await web3.nrg.getBlock(blockNumber);
        return block;
    }
    catch (err) {
        throw new Error(`Error fetching block information, Error: ${err}`);
    }
};

export default fetchBlockInformation;
