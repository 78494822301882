import React, {useState, useEffect} from 'react';
import {Button} from '@rmwc/button';
import {TabBar, Tab} from '@rmwc/tabs';
import CollapsableSection from '../../collapsable-section';
import translations from '../../../lib/translations';
import {isUuidv4} from '../../../lib/helpers/uuid';
import InputField from '../../storybook/input-field';

const Voting = ({enabled, onAccept, onReject, proposals, ...props}) => {
    let proposalFieldValid, formValid, uuidErrorMsg;
    const [activeVotingTab, setActiveVotingTab] = useState(0),
        [proposalField, setProposalField] = useState(''),
        [isLoading, setIsLoading] = useState(false),
        translate = translations.init(props),
        votingMode = activeVotingTab === 1 ? 'no' : 'yes',
        localUUID = props.uuid;

    useEffect(() => {
        setProposalField(localUUID);
    }, [localUUID]);

    const handleSubmit = ev => {
        ev.preventDefault();

        if (!formValid) {
            return false;
        }


        (async () => {
            setIsLoading(true);

            if (activeVotingTab === 1) {
                await onReject({
                    proposal: proposalField
                });
            }
            else {
                await onAccept({
                    proposal: proposalField
                });
            }

            setProposalField('');
            setIsLoading(false);
        })();


        return false;
    };

    const setSafeProposalField = value => {
        setProposalField(value.itsa_trim());
    };

    proposalFieldValid = !!proposals.find(p => p.refUUID === proposalField);
    formValid = proposalFieldValid;
    if (isUuidv4(proposalField, true) && !proposalFieldValid) {
        uuidErrorMsg = translate.t('UUID_NOT_IN_LIST', true);
    }

    return (
        <CollapsableSection
            className="voting"
            sectionName="PROPOSAL_VOTING"
            startCollapsed={true}
            title={translate.t('PROPOSAL_VOTING', true)}>
            <div className={`tab-wrapper tab-wrapper--${votingMode}`}>
                <TabBar
                    activeTabIndex={activeVotingTab}
                    onActivate={evt => setActiveVotingTab(evt.detail.index)}>
                    <Tab>{translate.t('VOTE_YES', true)}</Tab>
                    <Tab className="red">{translate.t('VOTE_NO', true)}</Tab>
                </TabBar>
            </div>
            <form className="voting-form" onSubmit={handleSubmit}>
                <div className="voting-form-inner">
                    <div className="input-wrapper">
                        <InputField
                            disabled={!enabled}
                            isValid={proposalFieldValid}
                            label={translate.t(enabled ? 'ENTER_PROPOSAL' : 'NO_PROPOSALS', true)}
                            onChange={setSafeProposalField}
                            value={proposalField} />
                        <span className="error-message">{uuidErrorMsg}</span>
                    </div>
                    <Button
                        className={`btn btn-${votingMode === 'no' ? translate.t('NO') : translate.t('YES')}`}
                        disabled={!formValid || isLoading}
                        label={translate.t(`VOTE_${votingMode.toUpperCase()}`, true)}
                        raised />
                </div>
            </form>
        </CollapsableSection>
    );
};

export default Voting;
