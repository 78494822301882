import React, {useEffect, useState} from 'react';
import Web3 from 'web3';
import {DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell} from '@rmwc/data-table';
import {Icon} from '@rmwc/icon';
import {CircularProgress} from '@rmwc/circular-progress';
import {Tooltip} from '@rmwc/tooltip';
import {Select} from '@rmwc/select';
import CollapsableSection from '../../collapsable-section';
import usePagination from '../../../hooks/usePagination';
import translations from '../../../lib/translations';
import formatNumber from '../../../lib/helpers/bn-format';
import copyToClipboard from '../../../lib/helpers/clipboard';
import chevronLeft from '../../../images/chevron_left.svg';
import chevronRight from '../../../images/chevron_right.svg';
import sectionState from '../../../globals/sections';
import {Badge} from '../../SvgIcons';
import getProviderInfo from '../../../lib/helpers/network-providers';

const SECONDS_PER_MINUTE = 60,
    SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE,
    SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR,
    BN100 = Web3.utils.toBN('100');

const deadlineDate = (date, currentLang) => new Intl.DateTimeFormat(currentLang, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
}).format(date);

const deadLineRemainer = (seconds, translate) => {
    let remainer, hours, minutes, hoursString;
    if (seconds < 0) {
        // vote closed but not be removed yet from the list
        return translate.t('VOTING_CLOSED');
    }
    hours = Math.floor(seconds / SECONDS_PER_HOUR),
    remainer = seconds - (hours * SECONDS_PER_HOUR);
    minutes = Math.floor(remainer / SECONDS_PER_MINUTE);
    hoursString = hours ? `${hours} ${translate.t((hours === 1) ? 'HOUR' : 'HOURS')} ` : '';
    return `${hoursString}${minutes} ${translate.t((minutes === 1) ? 'MINUTE' : 'MINUTES')}`;
};

const VERIFIED_ADDRESSES = {
    // Energi Mainnet
    39797: [
        '0xf02b5c0beea7e30437a55d77bb82f2bacde40249',
    ],
    // Energi Testnet
    49797: [],
};

const Proposals = ({proposals, isLoading, langInfo, ...props}) => {
    let quickCopyClass = 'quick-copy',
        simpleMenuTop, timeout;

    const [rows, setRows] = useState([]),
        translate = translations.init(props),
        [simpleMenu, setSimpleMenu] = useState();

    const {
        activeRows,
        itemsPerPage,
        setItemsPerPage,
        nextPage,
        previousPage,
        canGoPreviousPage,
        canGoNextPage,
        showingFrom,
        showingTo,
    } = usePagination(proposals, 10);

    // Update table rows
    useEffect(() => {
        let needsSecondUpdate = false;

        clearTimeout(timeout);

        if (activeRows.length === 0) {
            return;
        }

        const lifeUpdateDeadlineCountdown = () => {
            const pageRows = activeRows.map(({
                proposal,
                proposer,
                rejectWeight,
                acceptWeight,
                proposedAmount,
                deadline,
                quorumWeight,
                refUUID,
            }) => {
                const remainingSeconds = parseInt((deadline.getTime() - Date.now()) / 1000, 10),
                    deadlineString = (remainingSeconds <= SECONDS_PER_DAY) ? deadLineRemainer(remainingSeconds, translate) : deadlineDate(deadline, langInfo.lang);
                const votes = acceptWeight.add(rejectWeight);
                const status = votes.mul(BN100).div(quorumWeight).toString();
                let classnameStatus = '';

                if (remainingSeconds <= SECONDS_PER_DAY) {
                    needsSecondUpdate = true;
                }
                if (votes.lt(quorumWeight)) {
                    classnameStatus = 'notenough-votes';
                }
                else if (acceptWeight.gt(rejectWeight)) {
                    classnameStatus = 'positive-votes';
                }
                else  {
                    classnameStatus = 'negative-votes';
                }

                return (
                    <DataTableRow key={proposal}>
                        <DataTableCell alignMiddle>{VERIFIED_ADDRESSES[getProviderInfo.chainId].includes(proposer) && <Badge size={24} />}</DataTableCell>
                        <DataTableCell>{refUUID}</DataTableCell>
                        <DataTableCell alignEnd>{formatNumber(langInfo.locales, proposedAmount, 2)}</DataTableCell>
                        <DataTableCell>{proposer}</DataTableCell>
                        <DataTableCell alignEnd className="accepted-votes">{formatNumber(langInfo.locales, acceptWeight, 0)}</DataTableCell>
                        <DataTableCell alignEnd className="rejected-votes">{formatNumber(langInfo.locales, rejectWeight, 0)}</DataTableCell>
                        <DataTableCell alignEnd className={classnameStatus}>{status + '%'}</DataTableCell>
                        <DataTableCell>{deadlineString}</DataTableCell>
                        <DataTableCell
                            alignMiddle
                            className="datatable-sticky-cell">
                            <Icon data-proposal={proposal} icon="more_vert" />
                        </DataTableCell>
                    </DataTableRow>);
            });

            setRows(pageRows);
            if (needsSecondUpdate) {
                timeout = setTimeout(lifeUpdateDeadlineCountdown, 1000); // Update every second
            }
        };

        lifeUpdateDeadlineCountdown();

        return () => !!timeout && clearTimeout(timeout);
    }, [activeRows]);

    useEffect(() => {
        document.addEventListener('click', handleDocClick); // do NOT set 3rd argument `true` -> that would make it impossible for clickHandler to stopPropagate
        return () => {
            // will be invoked on un-mount
            document.removeEventListener('click', handleDocClick);
        };
    }, []);

    const setSnackbarMsg = msg => {
        props.onSnackbarMsg(msg);
    };

    const handleDocClick = e => {
        const node = e.target;
        if ((node.tagName !== 'I') || !node.getAttribute('data-proposal')) {
            setSimpleMenu(false);
        }
    };

    const handleClick = e => {
        const node = e.target;
        let proposal, simpleMenuData, nodeTop;
        if (node.tagName === 'I') {
            proposal = node.getAttribute('data-proposal');
            if (proposal) {
                nodeTop = Math.round(node.getBoundingClientRect().top + window.pageYOffset);
                simpleMenuData = {
                    top: nodeTop,
                    proposal: node.getAttribute('data-proposal')
                };
                setSimpleMenu(simpleMenuData);
            }
        }
    };

    const contextMenu = (proposal, liNode) => {
        // first, we need to find out which liNode was clicked:
        const ulNode = liNode.parentNode,
            index = Array.prototype.indexOf.call(ulNode.children, liNode),
            item = proposals.find(record => record.proposal === proposal);

        let txtWhat = '',
            isCopiedToClipboard = false;

        if (!item || (index === -1)) {
            return;
        }

        switch (index) {
        case 0:
            props.onSelectedItem(item);
            props.onPage(1);
            return;
        case 1:
            isCopiedToClipboard = copyToClipboard(item.refUUID);
            txtWhat = translate.t('UUID', true);
            break;
        case 2:
            isCopiedToClipboard = copyToClipboard(item.proposer);
            txtWhat = translate.t('PROPOSER', true);
            break;
        case 3:
            props.setUUID(item.refUUID);
            sectionState.on.set({section: 'PROPOSAL_VOTING', scroll: true});
            return;
        }

        if (isCopiedToClipboard) {
            setSnackbarMsg(txtWhat + ' ' + translate.t('COPIED_CLIPBOARD'));
        }
        else {
            setSnackbarMsg(translate.t('ACTION_NOT_SUPPORTED', true));
        }
    };

    const LoaderRow = (
        <DataTableRow>
            <DataTableCell colSpan={9}>
                <CircularProgress />
            </DataTableCell>
        </DataTableRow>
    );

    if (!simpleMenu) {
        quickCopyClass += ' hidden';
    }
    else {
        simpleMenuTop = {top: simpleMenu.top + 'px'};
    }

    return (
        <CollapsableSection
            className="proposals"
            sectionName="PROPOSALS"
            startCollapsed={true}
            title={translate.t('PROPOSALS', true)}>
            <div className="table-container">
                <ul
                    className={quickCopyClass}
                    onClick={e => contextMenu(simpleMenu.proposal, e.target)}
                    style={simpleMenuTop}>
                    <li>{translate.t('VIEW_PROPOSAL_DETAILS')}</li>
                    <li>{translate.t('COPY_UUID')}</li>
                    <li>{translate.t('COPY_PROPOSER')}</li>
                    <li>{translate.t('VOTE_PROPOSAL')}</li>
                </ul>
                <DataTable className="proposal-list">
                    <DataTableContent>
                        <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell>
                                    <Tooltip content="Indicates the proposal was verified by the Energi Core">
                                        <span>Verified</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell>
                                    <Tooltip content={translate.t('PROPOSAL_UUID_EXPLANATION', true)}>
                                        <span>{translate.t('UUID', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell alignEnd>
                                    <Tooltip content={translate.t('PROPOSAL_REQUESTED_EXPLANATION', true)}>
                                        <span>{translate.t('REQUESTED', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell>
                                    <Tooltip content={translate.t('PROPOSAL_PROPOSER_EXPLANATION', true)}>
                                        <span>{translate.t('PROPOSER', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell alignEnd>
                                    <Tooltip content={translate.t('PROPOSAL_YES_EXPLANATION', true)}>
                                        <span>{translate.t('YES', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell alignEnd>
                                    <Tooltip content={translate.t('PROPOSAL_NO_EXPLANATION', true)}>
                                        <span>{translate.t('NO', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell alignEnd>
                                    <Tooltip content={translate.t('PROPOSAL_STATUS_EXPLANATION', true)}>
                                        <span>{translate.t('STATUS', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell>
                                    <Tooltip content={translate.t('PROPOSAL_VOTING_DEADLINE_EXPLANATION', true)}>
                                        <span>{translate.t('VOTING_DEADLINE', true)}</span>
                                    </Tooltip>
                                </DataTableHeadCell>
                                <DataTableHeadCell />
                            </DataTableRow>
                        </DataTableHead>
                        <DataTableBody onClick={handleClick}>
                            {isLoading ? LoaderRow : (rows.length > 0 ? rows : (
                                <DataTableRow activated={false} className="no-data-row">
                                    <DataTableCell alignMiddle colSpan={9}>
                                        <span className="no-data-text">{translate.t('NO_PROPOSALS', true)}</span>
                                    </DataTableCell>
                                </DataTableRow>
                            ))}
                        </DataTableBody>
                    </DataTableContent>
                </DataTable>
                <div className="table-footer">
                    <div className="navigation">
                        <span className="label-text">{translate.t('ROWS_PER_PAGE', true)}:</span>
                        <Select
                            enhanced
                            onChange={ev => setItemsPerPage(parseInt(ev.target.value, 10))}
                            options={[10, 25, 50, 100]}
                            value = {itemsPerPage} />
                        <span className="label-text">{showingFrom} - {showingTo} {translate.t('OF')} {proposals.length}</span>
                        <div className="navigation-buttons">
                            <button
                                className="btn-prev"
                                disabled={!canGoPreviousPage}
                                onClick={previousPage}>
                                <img alt="previous" src={chevronLeft} />
                            </button>
                            <button
                                className="btn-next"
                                disabled={!canGoNextPage}
                                onClick={nextPage}>
                                <img alt="next" src={chevronRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </CollapsableSection>
    );
};

export default Proposals;
