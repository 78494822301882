import React, {useEffect, useState} from 'react';
import {LinearProgress} from '@rmwc/linear-progress';
import CollapsableSection from '../../collapsable-section';
import translations from '../../../lib/translations';
import bnFormat from '../../../lib/helpers/bn-format';
import formatNumber from '../../../lib/helpers/number-format';

const useCountDownDate = (date, currentLang) => new Intl.DateTimeFormat(currentLang, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
}).format(date);

const SECONDS_PER_MINUTE = 60,
    SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE,
    SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

const Dashboard = ({latestProposal, balance, blockNumber, isLoading, langInfo, ...props}) => {
    const translate = translations.init(props),
        locales = langInfo.locales,
        countDownDate = latestProposal && useCountDownDate(latestProposal.deadline, langInfo.lang),
        [deadlineCountdown, setDeadlineCountdown] = useState(),
        superblockCycle = 60 * 24 * 14,
        nextSuperblock = Math.ceil(blockNumber / superblockCycle) * superblockCycle,
        remainingBlocks = superblockCycle - (blockNumber % superblockCycle);

    let dashboardContent, loadingSpinner, timeout;

    useEffect(() => {
        clearTimeout(timeout);

        if (!latestProposal) {
            return;
        }

        const updateDeadlineCountdown = () => {
            let days, remainer, hours, minutes, daysString, hoursString;
            const seconds = parseInt((latestProposal.deadline.getTime() - Date.now()) / 1000, 10); // In seconds

            days = Math.floor(seconds / SECONDS_PER_DAY);
            remainer = seconds - (days * SECONDS_PER_DAY);
            hours = Math.floor(remainer / SECONDS_PER_HOUR),
            remainer = remainer - (hours * SECONDS_PER_HOUR);
            minutes = Math.floor(remainer / SECONDS_PER_MINUTE);

            if (seconds < 0) {
                // vote closed but not removed yet from the list
                setDeadlineCountdown(translate.t('VOTING_CLOSED'));
            }
            else if (!days && !hours && !minutes) {
                // last minute
                setDeadlineCountdown(`${seconds} ${translate.t((seconds === 1) ? 'SECOND' : 'SECONDS')}`);
                timeout = setTimeout(updateDeadlineCountdown, 1000); // Update every second
            }
            else {
                daysString = days ? `${days} ${translate.t((days === 1) ? 'DAY' : 'DAYS')} ` : '';
                hoursString = hours ? `${hours} ${translate.t((hours === 1) ? 'HOUR' : 'HOURS')} ` : '';
                setDeadlineCountdown(`${daysString}${hoursString}${minutes} ${translate.t((minutes === 1) ? 'MINUTE' : 'MINUTES')}`);
                if (!days && !hours && (minutes === 1)) {
                    timeout = setTimeout(updateDeadlineCountdown, 1000); // Update every second
                }
                else {
                    remainer = remainer - (minutes * SECONDS_PER_MINUTE);
                    timeout = setTimeout(updateDeadlineCountdown, (remainer + 1) * 1000); // Update every minute
                }
            }
        };

        updateDeadlineCountdown();
        return () => !!timeout && clearTimeout(timeout);
    }, [latestProposal]);


    loadingSpinner = isLoading ? <LinearProgress className="loader" /> : (
        <div className="no-data">
            {translate.t('NO_DATA', true)}
        </div>
    );

    if (isLoading) {
        dashboardContent = loadingSpinner;
    }
    else {
        dashboardContent = (
            <>
                <div className="item item-start">
                    <div className="item-eyebrow">
                        {translate.t(latestProposal ? 'NEXT_VOTING_DEADLINE' : 'NO_PROPOSALS', true)}
                    </div>
                    <div className="item-text">
                        {countDownDate}
                    </div>
                    <div className="item-subtext">
                        {deadlineCountdown}
                    </div>
                </div>

                <div className="item">
                    <div className="item-eyebrow">
                        {translate.t('NEXT_SUPERBLOCK', true)}
                    </div>
                    <div className="item-text">
                        {formatNumber(locales, nextSuperblock, 0)}
                    </div>
                    <div className="item-subtext">
                        {formatNumber(locales, remainingBlocks, 0)} {translate.t('BLOCKS_REMAINING')}
                    </div>
                </div>

                <div className="item item-end">
                    <div className="item-eyebrow">
                        {translate.t('AVAILABLE_BUDGET', true)}
                    </div>
                    <div className="item-text">
                        {bnFormat(locales, balance, 0) + ' NRG'}
                    </div>
                </div>
            </>
        );
    }

    return (
        <CollapsableSection
            className="dashboard"
            sectionName="DASHBOARD"
            startCollapsed={false}
            title={translate.t('DASHBOARD', true)}>
            <div className="dashboard-table">
                {dashboardContent}
            </div>
        </CollapsableSection>
    );
};

export default Dashboard;
