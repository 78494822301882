import React, {useEffect, useState} from 'react';
import Web3 from 'web3';
import {useStore} from 'effector-react';
import {Snackbar, SnackbarAction} from '@rmwc/snackbar';
import energiExtension from '@energi/web3-ext';
import Footer from '../Footer';
import Title from '../Title';
import translations from '../../lib/translations';
import mockGovernance from '../../lib/smart-contracts/mock-governance';
import {networkOptions} from '../../lib/constants/network-info';
import queryString from '../../lib/helpers/query-string';
import {walletUrlAccountSend} from '../../lib/constants/external-wallet';
import treasury from '../../lib/smart-contracts/treasury';
import metamaskStore from '../../globals/metamask';
import blockPolling from '../../globals/block-polling';
import demoState from '../../globals/demo-usage';
import budgetInfo from '../../globals/budget-info';
import sectionState from '../../globals/sections';
import Dashboard from './treasury-budget/dashboard';
import Proposals from './treasury-budget/proposals';
import Voting from './treasury-budget/voting';
import CreateProposal from './treasury-budget/create-proposal';
import ProposalDetails from './treasury-budget/proposal-details';
import ConfirmProposal from './treasury-budget/confirm-proposal';

const web3 = new Web3(networkOptions.networkAddress);
energiExtension.extend(web3);

const TreasuryBudget = props => {
    const {balance, proposals} = useStore(budgetInfo.$store),
        blockNumber = useStore(blockPolling.$lastBlock),
        correctNetwork = useStore(metamaskStore.$correctNetwork),
        metamaskAddress = useStore(metamaskStore.$address),
        [errorMessage, setErrorMessage] = useState(''),
        [selectedItem, setSelectedItem] = useState(null),
        [uuid, setUUId] = useState(''),
        [page, setPage] = useState(0),
        isDemo = demoState.$demo.getState(),

        [amountField, setAmountField] = useState(''),
        [payerField, setPayerField] = useState(''),
        [proposalInfoField, setProposalInfoField] = useState(''),
        [periodField, setPeriodField] = useState(''),

        translate = translations.init(props),
        isLoadingBudgetInfo = (balance === null);

    let voteAccept, voteReject, proposalPayer;

    if (isDemo) {
        ({voteAccept, voteReject} = mockGovernance);
    }
    else {
        ({voteAccept, voteReject} = treasury(web3));
    }

    // Fetch proposals and block number
    useEffect(() => {
        budgetInfo.on.startPolling();
        return () => {
            budgetInfo.on.stopPolling();
            sectionState.on.set('DASHBOARD');
        };
    }, []);

    const setupTransaction = async (fn) => {
        let tx, serialisedTx, url, windowReference;
        try {
            tx = await fn();
            if (typeof tx !== 'string') { // will be a String in case of MetaMask, where txData would be the tx hash
                windowReference = window.open();
                serialisedTx = queryString(tx);
                url = walletUrlAccountSend + '/?' + serialisedTx;
                windowReference.location = url;
            }
        }
        catch (err) {
            console.error(err);
            setErrorMessage(translate.t('SOMETHING_WENT_WRONG_TRANSACTION', true) + '.');
            return;
        }
    };

    const handleVote = async (vote, {proposal}) => {
        const fn = vote === 'accept' ? voteAccept : voteReject;
        await setupTransaction(fn.bind(null, proposal));
    };

    const setSnackbarMsg = msg => {
        setErrorMessage(msg);
    };

    const resetNewProposal = () => {
        setAmountField('');
        setPayerField('');
        setProposalInfoField('');
        setPeriodField('');
        sectionState.on.set('DASHBOARD');
        setPage(0);
        window.scrollTo(0, 0);
    };

    if (page === 1) {
        return (<ProposalDetails
            {...props}
            data={{...selectedItem}}
            setState={setPage} />);
    }

    if (page === 2) {
        proposalPayer = (correctNetwork && metamaskAddress) ? metamaskAddress : payerField;
        return (<ConfirmProposal
            {...props}
            amountField={amountField}
            onReset={resetNewProposal}
            onSetErrorMessage={setErrorMessage}
            payerField={proposalPayer}
            periodField={periodField}
            proposalInfoField={proposalInfoField.itsa_trim()}
            setState={setPage}
            setupTransaction={setupTransaction}
            web3={web3} />);
    }

    return (
        <div className="treasury-budget pure-u subpage">
            <Title
                subTitle={translate.md('treasury-budget')}
                title={translate.t('TREASURY_BUDGET')} />
            <Dashboard
                {...props}
                balance={balance}
                blockNumber={blockNumber}
                isLoading={isLoadingBudgetInfo || (blockNumber === 0)}
                latestProposal={proposals.length > 0 ? proposals[0] : undefined} />
            <Proposals
                {...props}
                isLoading={isLoadingBudgetInfo}
                onPage={setPage}
                onSelectedItem={setSelectedItem}
                onSnackbarMsg={setSnackbarMsg}
                proposals={proposals}
                setUUID={setUUId} />
            <Voting
                {...props}
                enabled={proposals.length > 0}
                onAccept={(info) => handleVote('accept', info)}
                onReject={(info) => handleVote('reject', info)}
                proposals={proposals}
                uuid={uuid} />
            <CreateProposal
                {...props}
                amountField={amountField}
                onAmountField={setAmountField}
                onPage={setPage}
                onPayerField={setPayerField}
                onPeriodField={setPeriodField}
                onProposalInfoField={setProposalInfoField}
                payerField={payerField}
                periodField={periodField}
                proposalInfoField={proposalInfoField} />
            <Footer {...props} />

            <Snackbar
                action = {
                    <SnackbarAction
                        label={translate.t('CLOSE')}
                        onClick={() => setErrorMessage('')} />
                }
                leading
                message={errorMessage}
                onClose={() => setErrorMessage('')}
                open={!!errorMessage} />
        </div>
    );
};

export default TreasuryBudget;
