const {v4} = require('uuid');

const randHex = len => {
    const maxlen = 8,
        min = Math.pow(16, Math.min(len, maxlen) - 1),
        max = Math.pow(16, Math.min(len, maxlen)) - 1,
        n = Math.floor(Math.random() * (max - min + 1)) + min;
    let r = n.toString(16);
    while (r.length < len) {
        r = r + randHex(len - maxlen);
    }
    return r;
};

const randFloat = (min, max) => {
    return Math.random() * (max - min + 1) + min;
};

const randInt = (min, max) => {
    return Math.floor(randFloat(min, max));
};

module.exports = {
    randHex,
    randFloat,
    randInt,
    randUUID: v4,
};
