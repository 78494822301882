import React, {useState} from 'react';
import {TextField} from '@rmwc/textfield';

const InputField = ({
    disabled,
    value,
    isValid: isCurrentlyValid,
    onChange,
    ...props
}) => {
    const [isValid, setIsValid] = useState(true);

    const handleChange = (ev) => {
        onChange(ev.target.value);
    };

    const handleBlur = () => {
        setIsValid(isCurrentlyValid);
    };

    let validClass = '';
    if (!isCurrentlyValid && !isValid && value.length > 0) {
        validClass = 'mdc-text-field--focused error';
    }

    return (<TextField
        {...props}
        className={`input ${validClass}`}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        outlined
        value={value} />);
};

export default InputField;
