import 'itsa-jsext'; // needed for itsa_trimLeft and itsa_isValidFloat

import React, {useMemo, useState, useEffect} from 'react';
import {Button} from '@rmwc/button';
import {Select} from '@rmwc/select';
import {Checkbox} from '@rmwc/checkbox';
import Web3 from 'web3';
import {useStore} from 'effector-react';

import CollapsableSection from '../../collapsable-section';
import translations from '../../../lib/translations';
import metamaskStore from '../../../globals/metamask';
import InputField from '../../storybook/input-field';

const SEC_PER_DAY = 60 * 60 * 24,
    MS_PER_DAY = 1000 * SEC_PER_DAY,
    MIN_PROPOSED_AMOUNT = 100,
    MAX_PROPOSED_AMOUNT = 184000,
    MIN_PROPOSED_DESCRIPTION_CHARS = 30,
    MAX_PROPOSED_DESCRIPTION_CHARS = 300;

const getDateFormats = currentLang => {
    return useMemo(() => new Intl.DateTimeFormat(
        currentLang,
        {
            weekday: 'long',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
            timeZone: 'UTC',
        }
    ), [currentLang]);
};

const generatePeriods = dateFormats => {
    let i, t, periods;
    const now = Date.now();
    const twoWeeksFromNow = new Date(now + (14 * MS_PER_DAY));

    periods = [];
    for (i = 0; i < 14; i++) {
        t = new Date(twoWeeksFromNow.getTime() + (MS_PER_DAY * i));
        periods.push({
            label: dateFormats.format(t),
            value: SEC_PER_DAY * (i + 14),
        });
    }
    return periods;
};

const CreateProposal = ({amountField, langInfo, onAmountField, onPage, onPayerField, onPeriodField, onProposalInfoField, payerField, periodField, proposalInfoField, ...props}) => {
    const translate = translations.init(props),
        dateFormats = getDateFormats(langInfo.lang),
        correctNetwork = useStore(metamaskStore.$correctNetwork),
        metamaskAddress = useStore(metamaskStore.$address),
        [periodOptions, setPeriodOptions] = useState(generatePeriods(dateFormats)),
        [confirmedPaidAmount, setConfirmedPaidAmount] = useState(false),
        [confirmedRepay, setConfirmedRepay] = useState(false),
        [confirmedSameAddress, setConfirmedSameAddress] = useState(false),
        amountFieldValid = !isNaN(parseFloat(amountField)) && parseFloat(amountField) >= MIN_PROPOSED_AMOUNT && parseFloat(amountField) <= MAX_PROPOSED_AMOUNT,
        payerFieldValid = Web3.utils.isAddress(payerField),
        proposalInfoFieldTrimmed = proposalInfoField.itsa_trim(),
        proposalInfoFieldValid = proposalInfoFieldTrimmed.length >= MIN_PROPOSED_DESCRIPTION_CHARS && proposalInfoFieldTrimmed.length <= MAX_PROPOSED_DESCRIPTION_CHARS,
        manualPayerAddress = (!correctNetwork || !metamaskAddress);

    let proposalInfoHelpText, amountHelpText, proposalDescription, formValid, timeout, proposerAddress, wrapperClass;

    if (manualPayerAddress) {
        formValid = !(
            (amountField === '') || !amountFieldValid ||
            (payerField === '') || !payerFieldValid ||
            (proposalInfoField === '') || !proposalInfoFieldValid
        ) && confirmedPaidAmount && confirmedRepay && confirmedSameAddress;
    }
    else {
        formValid = !(
            (amountField === '') || !amountFieldValid ||
            (proposalInfoField === '') || !proposalInfoFieldValid
        ) && confirmedPaidAmount && confirmedRepay && confirmedSameAddress;
    }

    useEffect(() => {
        const updatePeriods = () => {
            setPeriodOptions(generatePeriods(dateFormats));
            timeout = setTimeout(updatePeriods, 1000); // Update every second
        };

        updatePeriods();
        return () => !!timeout && clearTimeout(timeout);
    }, []);

    const handleChangePeriod = e => {
        onPeriodField(e.target.value);
    };

    const handleSubmit = ev => {
        ev.preventDefault();
        if (!periodField) {
            // make sure periodField exists
            onPeriodField(periodOptions[periodOptions.length-1].value);
        }
        onPage(2);
        return false;
    };

    const setProposalInfo = val => {
        onProposalInfoField(val.itsa_trimLeft());
    };

    const setSafeNumber = value => {
        value = value.itsa_trim();
        if ((value === '') || value.itsa_isValidNumber()) { // just "number" type for Input, will also accept 'e'; needed finer restriction
            onAmountField(value);
        }
    };

    if (proposalInfoFieldTrimmed.length > 0 && proposalInfoFieldTrimmed.length < 30) {
        proposalInfoHelpText = `${30 - proposalInfoFieldTrimmed.length} ${translate.t('CHARACTERS_REMAINING')}...`;
    }
    else if (proposalInfoFieldTrimmed.length > 300) {
        proposalInfoHelpText = translate.t('MUST_BE_BETWEEN_CHARACTERS_LONG', true, {min: 30, max: 300});
    }

    if (amountField && (parseFloat(amountField) < 100 || parseFloat(amountField) > 184000)) {
        amountHelpText = translate.t('MUST_BE_BETWEEN_NRG', true, {min: 100, max: 184000});
    }

    proposalDescription = (
        <div className="input-wrapper">
            <InputField
                helpText={{
                    persistent: false,
                    validationMsg: true,
                    children: proposalInfoHelpText,
                }}
                isValid={proposalInfoFieldValid}
                label={translate.t('PROPOSAL', true)}
                minLength={30}
                onChange={setProposalInfo}
                rows={4}
                textarea
                value={proposalInfoField} />
        </div>
    );

    if (manualPayerAddress) {
        proposerAddress = (
            <div className="input-wrapper">
                <InputField
                    isValid={payerFieldValid}
                    label={translate.t('PROPOSER_ADDRESS', true)}
                    onChange={onPayerField}
                    value={payerField} />
            </div>
        );
        wrapperClass = 'input-wrapper';
    }
    else {
        wrapperClass = 'input-wrapper input-wrapper-full';
    }

    return (
        <CollapsableSection
            className="create-proposal"
            sectionName="CREATE_PROPOSAL"
            startCollapsed={true}
            title={translate.t('CREATE_PROPOSAL')}>
            <form className="create-proposal-form" onSubmit={handleSubmit}>
                <div className="create-proposal-form-inner">
                    <div className="field-row">
                        <div className={wrapperClass}>
                            <InputField
                                helpText={amountHelpText}
                                isValid={amountFieldValid}
                                label={translate.t('AMOUNT', true) + ' NRG'}
                                onChange={setSafeNumber}
                                value={amountField} />
                        </div>
                        {proposerAddress}
                    </div>
                    {proposalDescription}
                    <div className="info">
                        <div className="info-header">{translate.t('PERIOD', true)}: </div>
                        <div className="custom-select">
                            <Select
                                enhanced
                                onChange={handleChangePeriod}
                                options={periodOptions}
                                placeholder={undefined}
                                value={periodField || periodOptions[periodOptions.length-1].value} />
                        </div>
                    </div>
                    <Checkbox
                        checked={confirmedPaidAmount}
                        className="disclaimer-check spaced"
                        name="paidAmount"
                        onChange={() => setConfirmedPaidAmount(!confirmedPaidAmount)}>
                        {translate.t('CONFIRM_PAID_AMOUNT', true)}.
                    </Checkbox>
                    <Checkbox
                        checked={confirmedRepay}
                        className="disclaimer-check"
                        name="repay"
                        onChange={() => setConfirmedRepay(!confirmedRepay)}>
                        {translate.t('CONFIRM_REPAY', true)}.
                    </Checkbox>
                    <Checkbox
                        checked={confirmedSameAddress}
                        className="disclaimer-check"
                        name="sameAddress"
                        onChange={() => setConfirmedSameAddress(!confirmedSameAddress)}>
                        {translate.t('CONFIRM_SAME_ADDRESS', true)} <span>{props.gen3Address}</span> {translate.t('IS_SAFE_OWN',)}.
                    </Checkbox>
                    <Button
                        className="btn"
                        disabled={!formValid}
                        label={translate.t('CREATE_PROPOSAL', true)}
                        raised />
                </div>
            </form>
        </CollapsableSection>
    );
};

export default CreateProposal;
