import {useCallback, useState, useEffect, useReducer} from 'react';

const activePageReducer = (state, {type, maxPages}) => {
    const constrain = val => Math.min(Math.max(val, 1), maxPages);

    switch (type) {
    case 'first':
        return 1;
    case 'last':
        return maxPages;
    case 'next':
        return constrain(state + 1);
    case 'previous':
        return constrain(state - 1);
    default:
        throw new Error();
    }
};

const usePagination = (rows, defaultItemsPerPage) => {
    const [activePage, dispatchActivePage] = useReducer(activePageReducer, 1),
        [activeRows, setActiveRows] = useState([]),
        [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

    const firstPage = useCallback(() => {
        const maxPages = Math.ceil(rows.length / itemsPerPage);
        dispatchActivePage({
            type: 'first',
            maxPages,
        });
    }, [itemsPerPage, rows]);

    const lastPage = useCallback(() => {
        const maxPages = Math.ceil(rows.length / itemsPerPage);
        dispatchActivePage({
            type: 'last',
            maxPages,
        });
    }, [itemsPerPage, rows]);

    const nextPage = useCallback(() => {
        const maxPages = Math.ceil(rows.length / itemsPerPage);
        dispatchActivePage({
            type: 'next',
            maxPages,
        });
    }, [itemsPerPage, rows]);

    const previousPage = useCallback(() => {
        const maxPages = Math.ceil(rows.length / itemsPerPage);
        dispatchActivePage({
            type: 'previous',
            maxPages,
        });
    }, [itemsPerPage, rows]);

    // Update active rows based on page change
    useEffect(() => {
        const start = (activePage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        setActiveRows(rows.slice(start, end));
    }, [rows, activePage, itemsPerPage]);

    // Update active page based on itemsPerPage change
    useEffect(firstPage, [itemsPerPage]);

    const total = rows.length;

    return {
        activePage,
        activeRows,
        itemsPerPage,
        setItemsPerPage,
        nextPage,
        firstPage,
        lastPage,
        previousPage,
        canGoPreviousPage: activePage >= 2,
        canGoNextPage: Math.ceil(rows.length / itemsPerPage) !== activePage,
        showingFrom: (total === 0) ? 0 : (activePage - 1) * itemsPerPage + 1,
        showingTo: Math.min(((activePage - 1) * itemsPerPage) + itemsPerPage, total),
    };
};

export default usePagination;
