const Web3 = require ('web3'),
    regexV4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i),
    regexV4NonStrict = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i),
    CHAR_MAP = {
        0: '8',
        1: '8',
        2: '8',
        3: '8',
        4: '9',
        5: '9',
        6: '9',
        7: '9',
        8: 'a',
        9: 'a',
        a: 'a',
        b: 'a',
        c: 'b',
        d: 'b',
        e: 'b',
        f: 'b',
    };

const transformChar = character => CHAR_MAP[character.toLowerCase()] || '8'; // always return a valid value

const isUuidv4 = (str, nonStrict) => (nonStrict ? regexV4NonStrict : regexV4).test(str);

const proposalStructure = (weiAmount, period, timestamp, payer, proposalInfo, uuid) => {
    let raw = {
        weiAmount,
        period,
        timestamp,
        payer,
        proposalInfo
    };
    if (uuid) {
        raw.uuid = uuid;
    }
    return JSON.stringify(raw);
};

const uuidToHexBNString = uuid => {
    if (uuid.indexOf('-') !== -1) {
        uuid = Web3.utils.toBN('0x' + uuid.replace(/-/g, '')).toString();
    }
    return uuid;
};

const textToUUID = (weiAmount, period, timestamp, payer, proposalInfo) => {
    const stringified = proposalStructure(
            weiAmount,
            period,
            timestamp,
            payer,
            proposalInfo
        ),
        hash = Web3.utils.sha3(stringified),
        halfhash = hash.substr(2, 32),
        segments = [
            halfhash.substr(0, 8),
            halfhash.substr(8, 4),
            '4' + halfhash.substr(13, 3), // UUID regex defines the first character of segments[2] to be a '4'
            transformChar(halfhash.substr(16, 1)) + halfhash.substr(17, 3), // UUID regex defines the first character of segments[3] to be either '8', '9', 'a' or 'b':
            halfhash.substr(20, 12)
        ];
    return segments.join('-');
};

module.exports = {
    textToUUID,
    isUuidv4,
    regexV4,
    proposalStructure,
    uuidToHexBNString,
};
