import React, {useState, useRef, useEffect} from 'react';
import {useStore} from 'effector-react';
import {IconButton} from '@rmwc/icon-button';
import ReactResizeDetector from 'react-resize-detector';
import sectionState from '../globals/sections';

const CollapsableSection = ({title, children, className, sectionName, startCollapsed, ...props}) => {
    const [collapsed, setCollapsed] = useState(!!startCollapsed);
    const [contentHeight, setContentHeight] = useState('auto');
    const [enableTransition, setEnableTransition] = useState(false);
    const contentMeasureRef = useRef();
    const sections = useStore(sectionState.$section);
    const myRef = useRef(null);

    useEffect(() => {
        if (sectionName === sections.section) {
            setCollapsed(false);
            sections.scroll && scrollToRef(myRef);
        }
    }, [sections.section]);

    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 80);

    const calculateContentHeight = () => {
        if (contentMeasureRef.current) {
            setContentHeight(contentMeasureRef.current.clientHeight + 1);
        }
    };

    const toggleCollapsed = (collapsed, data) => {
        setEnableTransition(true);
        setCollapsed(collapsed);
        sectionState.on.set({section: data, scroll: false});
    };

    return (
        <section {...props} className={`collapsable-section ${className}`} ref={myRef}>
            <div className="section-title">
                <h3 onClick={() => toggleCollapsed(!collapsed, collapsed ? sectionName : 'DASHBOARD')}>{title}</h3>
                {collapsed ?
                    <IconButton icon="keyboard_arrow_down" onClick={() => toggleCollapsed(false, sectionName)} /> :
                    <IconButton icon="keyboard_arrow_up" onClick={() => toggleCollapsed(true, 'DASHBOARD')} />}
            </div>
            <div
                className="collapsable-content"
                onTransitionEnd={() => setEnableTransition(false)}
                style={{
                    height: collapsed ? 0 : contentHeight,
                    transitionProperty: enableTransition ? 'height' : 'none',
                }}>
                <div ref={contentMeasureRef}>
                    <ReactResizeDetector handleHeight onResize={calculateContentHeight}>
                        <div>
                            {children}
                        </div>
                    </ReactResizeDetector>
                </div>
            </div>
        </section>
    );
};

export default CollapsableSection;
