import Web3 from 'web3';
import energiExtension from '@energi/web3-ext';
import {createStore, createEffect} from 'effector';
import networkInfo from '../lib/constants/network-info';
import mockGovernance from '../lib/smart-contracts/mock-governance';
import blockPolling from './block-polling';
import demoState from './demo-usage';

const $lastBlock = blockPolling.$lastBlock,
    web3 = new Web3(networkInfo.networkAddress);

energiExtension.extend(web3);

let polling = false;

const deadLineSort = (a, b) => {
    if (a.deadline < b.deadline) {
        return -1;
    }
    else if (a.deadline > b.deadline) {
        return 1;
    }
    return 0;
};

const updateBudgetInfo = createEffect({
    handler: async () => {
        let budgetInfo;
        try {
            if (demoState.$demo.getState()) {
                budgetInfo = await mockGovernance.budgetInfo();
            }
            else {
                budgetInfo = await web3.energi.budgetInfo();
                budgetInfo.proposals.sort(deadLineSort);
            }
        }
        catch (err) {
            console.error(err);
        }
        return budgetInfo;
    }
});

const $store = createStore({
    balance: null,
    proposals: [],
});

$store.on(updateBudgetInfo.done, (_state, data) => data.result);

const on = {
    startPolling: async () => {
        polling = true;
        updateBudgetInfo();
    },
    stopPolling: () => {
        polling = false;
    }
};

$lastBlock.watch(async () => {
    if (polling) {
        await updateBudgetInfo();
    }
});

const budgetInfo = {
    $store,
    on,
    updateBudgetInfo,
};

export default budgetInfo;
