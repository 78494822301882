/**
 *
 * @module mock-governance.js
 * @class MockGovernence
 * @since 0.0.1
 */
const {toBN, toWei} = require('web3').utils;
const {delay} = require('itsa-utils');
const {randHex, randFloat, randInt, randUUID} = require('../helpers/rand');

const getBlockNumber = () => {
    return randInt(10000, 100000);
};

// BudgetInfo() (*BudgetInfo, error)
const budgetInfo = async () => {
    await delay(1000);
    const nextMonday = new Date();
    nextMonday.setDate(nextMonday.getDate() + (7-nextMonday.getDay())%7+1);
    const proposals = new Array(50).fill(undefined).map((_, index, arr) => {
        const isGovernance = randInt(0, 1) === 0;

        // Governance proposals are 1
        const finished = index === arr.length - 1 ? 0 : 1;
        const accepted = finished && Boolean(randInt(0, 2));
        const amountEther = isGovernance ? 1 : randFloat(3000, 400000);
        const amount = toWei(amountEther.toString(), 'ether');
        const paidAmountEther = accepted ? amountEther : Math.floor(amountEther * randFloat(0.2, 1));
        const paidAmount = toWei(paidAmountEther.toString(), 'ether');
        return {
            proposal:       `0x${randHex(40)}`,
            proposer:       `0x${randHex(40)}`,
            createdBlock:   index * 1000,
            deadline:       index === arr.length - 1 ?
                nextMonday.getTime() / 1000 :
                Date.now() / 1000 - 60 * 60 * 24 * 7 * (arr.length - index),
            quorumWeight:   toBN(randInt(20, 200)),
            totalWeight:    toBN(randInt(20, 200)),
            rejectWeight:   toBN(randInt(20, 200)),
            acceptWeight:   toBN(randInt(20, 200)),
            finished,
            accepted,
            balance:        toBN(amount),
            paidAmount:     toBN(paidAmount),
            proposedAmount: toBN(amount),
            refUUID:        randUUID(),
        };
    });

    return {
        balance: toBN(toWei(randFloat(0, 400000).toString(), 'ether')),
        proposals: proposals.reverse(),
    };
};

/*
 * BudgetPropose(
 * 	 amount *hexutil.Big,
 * 	 ref_uuid string,
 * 	 period uint64,
 * 	 fee *hexutil.Big,
 * 	 payer common.Address,
 * 	 password *string,
 * ) (txhash common.Hash, err error)
 */
// eslint-disable-next-line no-unused-vars
const budgetPropose = async (amount, refUuid, period, payer, password) => {
    await delay(1000);
    return randHex(40);
};

/*
 * VoteAccept(
 * 	 proposal common.Address,
 * 	 mn_owner common.Address,
 * 	 password *string,
 * ) (txhash common.Hash, err error)
 */
// eslint-disable-next-line no-unused-vars
const voteAccept = async (uuid, privateKey) => {
    if (!privateKey) {
        return {
            gasLimit: '3000000',
            value: 0,
            to: `0x${randHex(40)}`,
            data: `0x${randHex(40)}`,
            warnings: 0, // suppress warnings from EnergiWallet
            ts: Date.now(), // forcing non cachable url
        };
    }
    await delay(1000);
    return randHex(40);
};

/*
 * VoteReject(
 * 	 proposal common.Address,
 * 	 mn_owner common.Address,
 * 	 password *string,
 * ) (txhash common.Hash, err error)
 */
// eslint-disable-next-line no-unused-vars
const voteReject = async (uuid, privateKey) => {
    if (!privateKey) {
        return {
            gasLimit: '3000000',
            value: 0,
            to: `0x${randHex(40)}`,
            data: `0x${randHex(40)}`,
            warnings: 0, // suppress warnings from EnergiWallet
            ts: Date.now(), // forcing non cachable url
        };
    }
    await delay(1000);
    return randHex(40);
};

module.exports = {
    budgetInfo,
    budgetPropose,
    voteAccept,
    voteReject,
    getBlockNumber,
};
